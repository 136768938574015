<template>
  <div class="myLesson">
    <div class="nav-btn">
      <router-link :to="`/user/lessonNetwork`" class="active"
        ><span>课程二维码</span></router-link
      >
      <router-link :to="`/user/networkStudentList`"
        ><span>学员列表</span></router-link
      >
    </div>

    <div v-if="list.length > 0">
      <ul class="lesson-list">
        <li class="container" v-for="(item, index) in list" :key="index">
          <div class="list">
            <h4 class="ell">{{ item.bookname }}</h4>
            <div class="main">
              <img v-lazy="item.images" />
              <div class="inner">
                <p>
                  课程价格：
                  <span class="gold-num">{{ item.price }}</span>
                </p>
                <div>
                  <button class="btn-info" @click="getStudentList(item.id)">
                    学员列表
                  </button>
                </div>
                <div>
                  <button
                    class="btn-default"
                    @click="getTeacherId(item.id, 'qrCode')"
                  >
                    获取二维码
                  </button>
                  <button
                    class="btn-success"
                    @click="createTrill(item.id, 'douyin', item.bookname)"
                  >
                    抖音二维码
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <Loading :loaded="loadendAll" :loading="loadingAll"></Loading>
    </div>
    <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div>

    <!-- 老师列表 -->
    <mt-popup v-model="teachaerVisible" position="center">
      <div class="teacher-list">
        <p>直播老师列表</p>
        <mt-radio v-model="teacher_id" :options="network_list"> </mt-radio>
        <div class="btn">
          <mt-button
            type="default"
            @click="
              teachaerVisible = false;
              teacher_id = '';
            "
            >取消</mt-button
          >
          <mt-button type="primary" @click="teacherSubmit()">确定</mt-button>
        </div>
      </div>
    </mt-popup>

    <!-- 二维码 -->
    <mt-popup v-model="qrcodeVisible" position="center" class="qr-img">
      <i class="jym-close dialog-close qr-close" @click="qrcodeVisible = false"></i>
      <img :src="qrcodeImage" />
    </mt-popup>

    <!-- 二维码 -->
    <mt-popup v-model="douyinVisible" position="center" class="douyin"  >
      <i class="jym-close dialog-close douyin-close" @click="douyinVisible = false"></i>
      <div class="wrap" id="html2canvas" ref="html2canvas">
        <img class="bg" src="@assets/images/douyin_1.png" />
        <div class="text text1">{{ bookname }}</div>
        <div class="text text2">{{ admin_name }}</div>
        <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div class="mark-img">
        <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
      </div>
      <img class="canvas-img" v-if="canvasUrl" :src="canvasUrl" />
    </mt-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Loading } from 'vant';
import html2canvas from "html2canvas";
import { Toast } from "mint-ui";
import { lessonPhoneList, studentpaybill } from "@api/user";
import QRCode from "qrcodejs2";
Vue.use(Loading);
export default {
  data() {
    return {
      sign: "",
      admin_id: "",
      lesson_id: "",
      teacher_id: "",
      is_network: "",
      qrcodeImage: "",
      bookname: "",
      canvasUrl: "",
      admin_name: "",
      list: [],
      network_list: [],
      teachaerVisible: false,
      qrcodeVisible: false,
      douyinVisible: false,
    };
  },
  watch: {
    teacher_id(val) {
      let that = this
      that.network_list.some((item) => {
        if(item.value == val) {
          that.admin_name = item.label
          return
        }
      })
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$dialog.loading.open();
      lessonPhoneList()
        .then((res) => {
          this.$set(this, "admin_id", res.data.admin_id);
          this.$set(this, "admin_name", res.data.admin_name);
          this.$set(this, "is_network", res.data.is_network);
          this.$set(this, "list", res.data.list);
          this.$set(
            this,
            "network_list",
            res.data.network_list.map((item) => {
              return {
                label: item.real_name,
                value: item.id,
              };
            })
          );
          this.$dialog.loading.close();
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
          this.$dialog.loading.close();
        });
    },

    teacherSubmit($event) {
      let that = this;

      if (!that.teacher_id) {
        that.$dialog.error("请选择一位直播老师！");
      } else {
        that.teachaerVisible = false;
        that.sign == "qrCode" ? that.getQRcode() : that.getDouyinUrl($event);
      }
    },

    // 判断是否直接获取招生老师id
    getTeacherId(id, type) {
      let that = this;

      that.sign = type;
      that.lesson_id = id;
      if (that.is_network == 1) {
        that.teachaerVisible = true;
      } else {
        that.getQRcode();
      }
    },

    // 创建抖音直接获取招生老师id
    createTrill(id, type, bookname) {
      let that = this;

      that.sign = type;
      that.lesson_id = id;
      that.bookname = bookname;
      that.canvasUrl = "";
      if (that.is_network == 1) {
        that.teachaerVisible = true;
      } else {
        that.getDouyinUrl();
      }
    },

    // 创建抖音二维码
    getDouyinUrl() {
      let that = this;
      
      that.$dialog.loading.open("二维码生成中！");
      that.douyinVisible = true;
      let url =
        location.origin +
        `/user/trillAddress?admin_id=` +
        that.admin_id +
        "&lesson_id=" +
        that.lesson_id +
        "&is_network=" +
        that.is_network +
        "&teacher_id=" +
        (that.is_network == 1 ? that.teacher_id : 0);
       
      that.createImage(url);
    },

    // 生成二维码
    createCode(url) {
      let that = this;
      document.getElementsByClassName("qrcode")[0].innerHTML = "";
      new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 80,
        height: 80,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },

    // 生成抖音二维码
    async createImage(url) {
      await this.createCode(url);
      html2canvas(this.$refs.html2canvas, {
        backgroundColor: null,
        useCORS: true,
        scrollY: 0,
        scrollX: 0,
      }).then((canvas) => {
        this.canvasUrl = canvas.toDataURL("image/png");
        this.$dialog.loading.close();
      });
    },

    // 获取二维码
    getQRcode() {
      let that = this;
      const params = {
        admin_id: that.admin_id,
        lesson_id: that.lesson_id,
        teacher_id: that.is_network == 1 ? that.teacher_id : 0,
        qrcode:
          window.location.origin +
          "/user/networkQRcode?lesson_id=" +
          that.lesson_id +
          "&admin_id=" +
          that.admin_id +
          "&is_network=" +
          that.is_network +
          "&teacher_id=" +
          that.teacher_id,
      };

      that.$dialog.loading.open("正在生成");
      studentpaybill(params)
        .then((res) => {
          that.qrcodeImage = res.data;
          that.teachaerVisible = false;
          that.qrcodeVisible = true;
          that.$dialog.loading.close();
        })
        .catch((err) => {
          Toast({ message: err.msg, iconClass: "jym-cuo" });
          that.$dialog.loading.close();
        });
    },

    // 学员列表
    getStudentList(id) {
      this.$router.push(`/user/networkStudentList?id=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.myLesson {
  padding-top: 0.88rem;
  padding-bottom: 1rem;
  .nav-btn {
    background: white;
    height: 0.88rem;
    font-size: 0.26rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    a {
      width: 50%;
      text-align: center;
      line-height: 0.88rem;
    }
    .active {
      color: #26a2ff;
      border-bottom: 2px solid #26a2ff;
    }
  }
  .container {
    background: white;
    width: 7.1rem;
    margin: 0.2rem 0.2rem 0;
    border-radius: 0.12rem;
    .list {
      width: 100%;
      padding: 0.1rem;
      h4 {
        font-size: 0.28rem;
        padding: 0.2rem 0 0.2rem 0;
      }
      .main {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.1rem;
        img {
          width: 2.6rem;
          height: 1.6rem;
          padding-right: 0.2rem;
        }
        .inner {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          width: 4.5rem;
          p {
            font-size: 0.26rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #656565;
          }
          button {
            height: 0.42rem;
            font-size: 0.22rem;
            color: white;
            border-radius: 0.04rem;
            padding: 0 0.3rem;
            margin-right: 0.2rem;
          }
        }
      }
      .gold {
        border: none;
        background: black;
        color: white;
      }
    }
  }
  .teacher-list {
    border-radius: 0.04rem;
    width: 6.5rem;
    padding: 0.1rem;
    p {
      font-size: 0.26rem;
      color: #26a2ff;
      line-height: 0.6rem;
      padding-left: 0.1rem;
    }
    .mint-radiolist {
      max-height: 7rem;
      overflow-y: scroll;
      min-height: 3rem;
    }
    .btn {
      height: 1rem;
      margin-top: 0.2rem;
      text-align: center;
      button {
        font-size: 0.24rem;
        height: 0.6rem;
        margin: 0 0.2rem;
        padding: 0 0.5rem;
      }
    }
  }
  .qr-img {
    width: 6.5rem;
    background: initial;
    .qr-close {
      margin-bottom: 1rem;
    }
    img {
      width: 100%;
    }
  }
  .mint-popup {
    top: 48%;
  }
  .douyin {
    width: 300px;
    height: 600px;
    .wrap {
      position: relative;
      width: 300px;
      height: 600px;
      .bg {
        width: 100%;
      }
      .qrcode {
        position: absolute;
        bottom: 45px;
        right: 110px;
        img {
          background-color: #fff;
          padding: 6px;
          box-sizing: border-box;  
        }
      }
      .text {
        position: absolute;
        overflow: hidden;
        line-height: 0.5rem;
        font-size: 0.28rem;
        width: 300px;
        text-align: center;
        letter-spacing: 1px;
      }
      .text1 {
        bottom: 135px;
      }
      .text2 {
        bottom: 10px;
      }
    }
    .canvas-img {
      width: 300px;
      height: 600px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
    }
    .mark-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      height: 600px;
      background: white;
      z-index: 10;
      text-align: center;
      line-height: 600px;
    }
  }
}
</style>